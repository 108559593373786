import { Box, Chip, Divider, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';

const LoadingBlog: React.FC = () => {
  
  return (
    <Paper elevation={6} sx={{ padding: "2em" }}>
      <Box>
        <Typography variant="h4" sx={{ width: "50%" }}><Skeleton /></Typography>
        <Typography sx={{ width: "25%" }}><Skeleton /></Typography>
        <Divider sx={{ margin: "1em 0" }} />
        <Box sx={{ flexDirection: 'row' }}>
          <Skeleton variant="rectangular" sx={{ borderRadius: "16px", display: "inline-flex", marginRight: "5px" }}><Chip label="poetry" /></Skeleton>
          <Skeleton variant="rectangular" sx={{ borderRadius: "16px", display: "inline-flex" }}><Chip label="poetry" /></Skeleton>
        </Box>
        <Divider sx={{ margin: "1em 0" }} />
        <Typography><Skeleton /></Typography>
      </Box>
    </Paper>
  );
}

export default LoadingBlog