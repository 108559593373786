import { LocalLibraryTwoTone } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cascades from '../assets/cascades.png';
import cascadesmountains from '../assets/cascades_mountains.png';
import ballardpark from '../assets/ballard_park.png';
import wonderland from '../assets/wonderland.png';
import { AboutCard, AboutCardProps } from '../components/AboutCard';

const About: React.FC = () => {
  const introTypo = `
    My only goal in life is to have a better website than my father.  So
    if you came here thinking you would find a professional looking dev profile,
    think again.  This is my playground for self-discovering my talent in
    front-end development, since I am already a godlike software engineer
    in other respects.  You will only find controversial personal opinions
    here.  Welcome.
  `;
  const secondTypo = `
    Why yes I took all of these photos personally.  I need a new iPhone though because
    my current one's lense is broken 😥. Any Apple employees here? I need to fill out an
    entire "About" page with content somehow.
  `;
  const thirdTypo = `
    Imagine that all you do all day is sitting in front of your home computer.  You work at
    Amazon.com trying to get shit done, but end up having a back and forth about ownership
    instead.  Maybe you swap out your work computer for your personal one and play some video
    games.  Shawarma King sounds good right about now.  But wait, you've gained 15 pounds since
    you moved to Seattle.  Time to hit the pool or the gym.  Think you are going to collapse? Nope!
    Time to stay up until 3am doing nothing!
  `;
  const fourthTypo = `
    That all said, I enjoy learning new things, which may be one of the reasons that I tried to build
    this website in React.js of all SPA frameworks.  I could have just used the much superior Angular!
    At least Angular had the sense to drop the reduntant ".js" back in 2016. I cannot even understand
    how front-end devs operated in the days before Angular and nice component frameworks like Material UI.
    Bootstrap? Don't make me laugh.
  `;
  const aboutCardProps: AboutCardProps[] = [
    {
      aboutText: introTypo,
      imageUrl: cascades
    },
    {
      aboutText: secondTypo,
      imageUrl: cascadesmountains
    },
    {
      aboutText: thirdTypo,
      imageUrl: ballardpark
    },
    {
      aboutText: fourthTypo,
      imageUrl: wonderland
    }
  ];
  const aboutCards: JSX.Element[] = aboutCardProps.map((props: AboutCardProps, idx: number) => {
    return <AboutCard key={idx} imageUrl={props.imageUrl} aboutText={props.aboutText} />
  });

  return (
    <Box>
      {aboutCards}
    </Box>
  )
}

export default About;