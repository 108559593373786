import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Blog from '../components/Blog';
import { BlogModel } from '../model/Blog.model';
import { LibraryBooks } from '@mui/icons-material'
import LoadingBlog from '../components/LoadingBlog';

const Blogs: React.FC = () => {

  const [blogs, setBlogs] = useState<BlogModel[]>([]);
  const [loading, setLoading] = useState(true);

  const loadBlogs = () => {
    fetch('https://20jm2iutyj.execute-api.us-east-1.amazonaws.com/default/getBlogs')
      .then(res => res.json())
      .then(json => json as BlogModel[])
      .then(b => setBlogs(b))
      .then(_ => setLoading(false));
  };

  useEffect(loadBlogs, []);

  const blogDivs = loading ? 
    [0, 1, 2, 3, 4, 5].map((i) => (
      <Grid item lg={4} md={6} xs={12} key={i}>
        <LoadingBlog />
      </Grid>
    )) : blogs.map((blog, i) => (
      <Grid item lg={4} md={6} xs={12} key={i}>
        <Blog id={blog.id} name={blog.name} headline={blog.headline} topics={blog.topics} created={blog.created} />
      </Grid>
    ));

  return (
    <Box>
      <Paper elevation={8} sx={{ padding: "2em", mb: "2em" }}>
        <Typography variant="h3" sx={{ mb: "0.5em" }}>
          <LibraryBooks fontSize="large" sx={{ mr: "0.5em" }} />
          Blogs and Writing
        </Typography>
        <Typography variant="body1">
          You may find beauty within these pages.
          You may find mental stimulation, or controversy.
          Most of all, you will see who I truly am.  Beware,
          especially if you are trying to hire me.  There will be hot takes.
          There will be politics.  It all comes from a desire to
          see the world as it is and refuse.
        </Typography>
      </Paper>
      <Grid container spacing={2}>
        {blogDivs}
      </Grid>
    </Box>
  );
};

export default Blogs;