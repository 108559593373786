import React, { useState } from 'react';
import profpic from './assets/profile-pic.png';
import './App.scss';
import { Link, Routes, Route, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import About from './pages/About';
import Projects from './pages/Projects';
import Blogs from './pages/Blogs';
import BlogDetail from './pages/BlogDetail';
import { AppBar, Avatar, Box, Button, Container, Toolbar, Typography, IconButton, Paper, Drawer, List, ListItem, Divider, ListItemIcon, ListItemText } from '@mui/material';
import { Menu, PanTool, FileOpen, LibraryBooks, Construction } from '@mui/icons-material';


const App: React.FC = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  };

  const openSocialMedia = (socialMediaLink: string) => {
    setDrawerOpen(false);
    setTimeout(() => {
      window.open(socialMediaLink, '_blank');
    }, 350);
  };
  const fbUrl = "https://facebook.com/feldmanleo";
  const linkedInUrl = "https://linkedin.com/in/leoafeldman";
  const githubUrl = "https://github.com/EmperorLeo";
  const instaUrl = "https://www.instagram.com/leonitusaf";

  return (
    <Box>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ gap: '1em', justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            <Toolbar disableGutters sx={{ alignSelf: 'flex-start', marginRight: 'auto', gap: '1em' }}>
              <Avatar alt="Leo Feldman" src={profpic} />
              <Typography>feldman.dev</Typography>
            </Toolbar>
            <NavLink to={'/'}><Button variant="contained" color="primary">About</Button></NavLink>
            <NavLink to={'/projects'}><Button variant="contained" color="primary">Projects</Button></NavLink>
            <NavLink to={'/blogs'}><Button variant="contained" color="primary">Blogs</Button></NavLink>
            <Box color="secondary">
              <Paper variant="outlined">
                <IconButton color="secondary"><a href={fbUrl} target="_blank" rel="external noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></IconButton>
                <IconButton color="secondary"><a href={linkedInUrl} target="_blank" rel="external noopener noreferrer"><FontAwesomeIcon className="free" icon={faLinkedinIn} /></a></IconButton>
                <IconButton color="secondary"><a href={githubUrl} target="_blank" rel="external noopener noreferrer"><FontAwesomeIcon icon={faGithub} /></a></IconButton>
                <IconButton color="secondary"><a href={instaUrl} target="_blank" rel="external noopener noreferrer"><FontAwesomeIcon className="free" icon={faInstagram} /></a></IconButton>
              </Paper>
            </Box>
          </Toolbar>
          <Toolbar disableGutters sx={{ justifyContent: 'flex-end', display: { md: 'none' } }}>
            <IconButton onClick={toggleDrawer} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 'auto' }}>
              <Menu />
            </IconButton>
            <Avatar alt="Leo Feldman" src={profpic} />
            <Typography sx={{ ml: '1em' }}>feldman.dev</Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ padding: "2em" }}>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/:id' element={<BlogDetail />} />
        </Routes>
      </Container>
      <Drawer anchor='left' open={drawerOpen} onClose={(evt) => setDrawerOpen(false)}>
        <Box>
          <List>
            <ListItem sx={{ pl: "2em", pr: "2em" }}>
              <Typography variant="h6">feldman.dev</Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <NavLink to={'/'}>
              <ListItem button key={'About'} sx={{ pl: "2em", pr: "2em" }} onClick={() => setDrawerOpen(false)}>
                <ListItemIcon>
                  <FileOpen />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItem>
            </NavLink>
            <NavLink to={'/projects'}>
              <ListItem button key={'Projects'} sx={{ pl: "2em", pr: "2em" }} onClick={() => setDrawerOpen(false)}>
                <ListItemIcon>
                  <Construction />
                </ListItemIcon>
                <ListItemText primary="Projects" />
              </ListItem>
            </NavLink>
            <NavLink to={'/blogs'}>
              <ListItem button key={'Blogs'} sx={{ pl: "2em", pr: "2em" }} onClick={() => setDrawerOpen(false)}>
                <ListItemIcon>
                  <LibraryBooks />
                </ListItemIcon>
                <ListItemText primary="Blogs" />
              </ListItem>
            </NavLink>
          </List>
          <Divider />
          <List>
            <ListItem button key={'Facebook'} sx={{ pl: "2em", pr: "2em" }} onClick={() => openSocialMedia(fbUrl)}>
              <ListItemIcon>
                <FontAwesomeIcon size='lg' icon={faFacebook} />
              </ListItemIcon>
              <ListItemText primary="Facebook" />
            </ListItem>
            <ListItem button key={'Instagram'} sx={{ pl: "2em", pr: "2em" }} onClick={() => openSocialMedia(instaUrl)}>
              <ListItemIcon>
                <FontAwesomeIcon size='lg' icon={faInstagram} />
              </ListItemIcon>
              <ListItemText primary="Instagram" />
            </ListItem>
            <ListItem button key={'Github'} sx={{ pl: "2em", pr: "2em" }} onClick={() => openSocialMedia(githubUrl)}>
              <ListItemIcon>
                <FontAwesomeIcon size='lg' icon={faGithub} />
              </ListItemIcon>
              <ListItemText primary="Github" />
            </ListItem>
            <ListItem button key={'LinkedIn'} sx={{ pl: "2em", pr: "2em" }} onClick={() => openSocialMedia(linkedInUrl)}>
              <ListItemIcon>
                <FontAwesomeIcon size='lg' icon={faLinkedinIn} />
              </ListItemIcon>
              <ListItemText primary="LinkedIn" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default App;
