import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';

export interface AboutCardProps {
  aboutText: string;
  imageUrl: string;
}

export const AboutCard: React.FC<AboutCardProps> = (props: AboutCardProps) => {
  return (
    <Card sx={{ display: { md: 'flex' }, marginBottom: "1em" }}>
      <CardMedia component="img" image={props.imageUrl} sx={{ width: { md: '400px' } }}></CardMedia>
      <CardContent>
        <Typography color="text.secondary">
          {props.aboutText}
        </Typography>
      </CardContent>
    </Card>
  )
};