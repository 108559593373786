import React from 'react';
import { Typography, Paper, Box, Divider } from '@mui/material';

type ProjectProps = {
  name: string;
  imgUrl: string;
  description: string;
  githubLink: string;
}

const Project: React.FC<ProjectProps> = (props: ProjectProps) => {
  const openGithubLink = () => {
    window.open(props.githubLink, '_blank');
  };

  let cardMedia;
  if (props.imgUrl) {
    cardMedia = <Box onClick={openGithubLink} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}><img width="250" alt={props.name} src={props.imgUrl}></img></Box>
  }

  return (
    <Paper elevation={4} sx={{ display: 'flex', marginBottom: '1em', flexDirection: { xs: 'column-reverse', sm: 'row' } }}>
        {cardMedia}
        <Divider sx={{display: { xs: 'block', sm: 'none' }, mb: '1em' }} />
        <Box sx={{ padding: "1em" }}>
          <Typography variant="h5">{props.name}</Typography>
          <Typography>
            {props.description}
          </Typography>
        </Box>
    </Paper>
  );
}

export default Project;