import { Chip, Paper, Typography, Box, Divider } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type BlogProps = {
  id: string,
  name: string,
  topics: string[],
  headline: string,
  created: number
}

const Blog: React.FC<BlogProps> = (props: BlogProps) => {
  const colorMap: {[key: string]: string} = {
    poetry: 'orange',
    politics: 'blue',
    technology: 'teal'
  };

  const topics = props.topics.map((topic: string, idx: number) => {
    let color = 'black';
    if (colorMap.hasOwnProperty(topic)) {
      color = colorMap[topic];
    }

    return <Chip key={idx} color="primary" label={topic} variant="outlined" sx={{ backgroundColor: color }}></Chip>
  });
  const createdDate = new Date(props.created).toLocaleDateString('en-US');

  return (
    <Link to={`/blogs/${props.id}`}>
      <Paper elevation={6} sx={{ padding: "2em" }}>
        <Box>
          <Typography variant="h4">{props.name}</Typography>
          <Typography>{createdDate}</Typography>
          <Divider sx ={{ margin: "1em 0" }}/>
          <Box sx={{ flexDirection: 'row' }}>
            {topics}
          </Box>
          <Divider sx ={{ margin: "1em 0" }}/>
          <Typography>{props.headline}</Typography>
        </Box>
      </Paper>
    </Link>
  );
}

export default Blog;