import React from 'react';
import Project from '../components/Project';
import missZ from '../assets/missz.png';
import elementalChess from '../assets/elemental-chess.png';
import plogBot from '../assets/plogbot.png';
import threshold from '../assets/threshold.png';
import ff from '../assets/ff.png';
import feldmandev from '../assets/feldmandev.png';
import { Box, Fade, Paper, Typography } from '@mui/material';

class ProjectModel {
  imgUrl: string;
  name: string;
  description: string;
  githubLink: string;

  constructor(imgUrl: string, name: string, description: string, githubLink: string) {
    this.imgUrl = imgUrl;
    this.name = name;
    this.description = description;
    this.githubLink = githubLink;
  }
}

const ffDescription: string = `
An app that lets writers build their stories in phases,
share, accept criticism, and self publish.
`;
const groupingAppDesc: string = `
I made this app for a friend who is training to be a teacher.
It allows a user to give an input of students (anyone really),
a group size or a nunber of groups, and get perfectly randomized
groups. This is perfect for when you need to assign groups, especially
if you don't want to put the social pressure on young people to self-organize.
`;
const elementalChessDesc = `
This was for a hackathon project at Relias. I worked with my teammates to create
a chess game with crazy rules.  Want to play as fire? Blaze a trail of flame through
2 pieces and capture both.  Want to play as air? Gain the power of mobility and swiftly
make your way across the board.
`;
const plogBotDesc = `
Back when I played Blade & Soul, I wrote this discord bot to keep track of our
active members, their stats, and to be a helper for planned clan events! It went
very well until I stepped on someone's freedom of speech. Did you know that when a
bot deletes someone's message, it doesn't show up in the audit log? (At least back then
it didn't.)
`;
const thresholdDesc = `
I used to be a swimmer in high school. Back then, I religiously tracked my time and my
progress in an excel sheet, hoping that one day I would become good.  I never did, but
that didn't prevent me from making an iOS app that was a companion to my effort to tracking
swim times and progress!
`;
const feldmanDevDesc = `
Once upon a time in 2019, I got really excited when Google released .dev domains, so I snapped
mine up immediately for $12. Clearly I made the right choice, because my cousin messaged me on
LinkedIn that same year demanding the rights to the domain.  Demanding, meaning he laughed about it.
Anyway, what better place to document the evolution of feldman.dev than on feldman.dev? (Github link
coming soon!)
`;


const Projects: React.FC = () => {
  const myProjects = [
    new ProjectModel(elementalChess, "Elemental Chess", elementalChessDesc, "https://github.com/EmperorLeo/ElementalChess"),
    new ProjectModel(plogBot, "Plog Bot", plogBotDesc, "https://github.com/EmperorLeo/PlogBot"),
    new ProjectModel(ff, "Fiction Fantasy", ffDescription, "https://github.com/EmperorLeo/fiction-fantasy-app"),
    new ProjectModel(threshold, "Threshold", thresholdDesc, "https://github.com/EmperorLeo/comprehensive-swim-app"),
    new ProjectModel(missZ, "MissZ Grouping App", groupingAppDesc, "https://github.com/EmperorLeo/missz-groupingapp"),
    new ProjectModel(feldmandev, "feldman.dev", feldmanDevDesc, "https://github.com/EmperorLeo")
  ];

  const projectDivs = myProjects.map((proj, i) => (
      <Fade in timeout={700}>
        <Box key={i}><Project imgUrl={proj.imgUrl} name={proj.name} description={proj.description} githubLink={proj.githubLink} /></Box>
      </Fade>
  ));

  return (
      <Box>
        <Paper elevation={8} sx={{ padding: "2em", mb: "2em" }}>
          <Typography variant="h3" sx={{ mb: "0.5em" }}>Projects</Typography>
          <Typography variant="body1">I just want to say that I rarely build anything anymore, so here's to reminiscing!</Typography>
        </Paper>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* <TransitionGroup timeout={50000} className="feldman-transition"> */}
            {projectDivs}
          {/* </TransitionGroup> */}
        </Box>
      </Box>
  );
}

export default Projects;