import { Box, Paper, Skeleton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { BlogModel } from '../model/Blog.model';

const BlogDetail: React.FC = () => {

  const { id } = useParams();

  const [blog, setBlog] = useState<BlogModel>();
  const [content, setContent] = useState<string>();

  const blogHeader = blog ?
    <Box>
      <Typography variant="h3">{blog ? blog.name : ''}</Typography>
      <Typography variant="body1">{blog ? blog.headline : ''}</Typography>
    </Box> :
    <Box>
      <Typography variant="h3" sx={{ width: "50%" }}><Skeleton /></Typography>
      <Typography variant="h3" sx={{ width: "75%" }}><Skeleton /></Typography>
    </Box>;

  const blogContent = content ? 
    <ReactMarkdown>
      {content}
    </ReactMarkdown> :
    <Skeleton variant="rectangular" sx={{ width: "100%", height: "20em" }} />;

  useEffect(() => {
    fetch(`https://20jm2iutyj.execute-api.us-east-1.amazonaws.com/default/getBlogs/${id}`)
      .then(res => res.json())
      .then(json => json as BlogModel)
      .then(b => { 
         setBlog(b);
         return b.location;
      })
      .then(location => fetch(`https://d31kxobtrqxwq9.cloudfront.net/${location}.md`))
      .then(response => response.text())
      .then(mdContent => setContent(mdContent));
  }, [id]);

  return (
    <Box>
      <Paper elevation={8} sx={{ padding: "2em", mb: "2em" }}>
        { blogHeader }
      </Paper>
      
      <Paper elevation={6} sx={{ padding: "2em" }}>
        { blogContent }
      </Paper>
    </Box>
  );
}

export default BlogDetail;