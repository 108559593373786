import { createTheme } from "@mui/material";
import { green, blueGrey } from "@mui/material/colors";

export const LeoTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: green[900]
    },
    secondary: {
      main: blueGrey[700]
    }
  }
});
